import React, { useState, useMemo, useRef } from 'react';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const FAQPage = () => {
  const title = 'よくある質問｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    'ホームページ制作やWebマーケティングに関するよくある質問をまとめました。';

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'よくある質問', url: 'https://www.involve-in.jp/faq' },
  ];

  // FAQデータ
  const faqs = useMemo(
    () => [
      {
        category: 'インヴォルブについて',
        id: 'about',
        questions: [
          {
            q: 'インヴォルブは何ができますか？',
            a: 'インヴォルブは、ホームページ制作、システムの開発、インターネットを使った集客をサポートする事務所です。サイトのデザインや構築はもちろん、検索対策や広告運用、運営のお手伝いまで対応します。',
          },
          {
            q: 'インヴォルブはどこの事務所ですか？',
            a: '奈良県橿原市にあるホームページ制作事務所です。',
          },
          {
            q: 'インヴォルブの強みは何ですか？',
            a: 'ホームページ制作だけでなく、集客や運営サポートまで幅広く対応できる点です。ITが苦手な方でも安心して相談できるよう、わかりやすい説明を心がけています。',
          },
          {
            q: '他のホームページ制作会社と何が違いますか？',
            a: '「作って終わり」ではなく、その後の運用や集客まで支援できる点です。ホームページを活用して成果を出すための仕組みづくりをサポートします。',
          },
          {
            q: 'どの地域の案件に対応していますか？',
            a: '当事務所は奈良に拠点を構えておりますが、大阪・京都・和歌山・三重・兵庫・滋賀のお客様にも対応実績がございます。対面でのお打ち合わせも可能ですので、お気軽にご相談ください。',
          },
          {
            q: '対面での打ち合わせは可能ですか？',
            a: '可能です！お伺いしての打ち合わせも承ります。',
          },
          {
            q: '初めてのホームページ制作で何も分からないのですが、大丈夫ですか？',
            a: 'もちろん大丈夫です！わかりやすく丁寧にご説明いたしますので、安心してご相談ください。',
          },
        ],
      },
      {
        category: '料金・契約について',
        id: 'pricing',
        questions: [
          {
            q: 'ホームページ制作の費用はどれくらいかかりますか？',
            a: '制作費用は30万円～60万円程度が一般的な目安です。ご予算やご要望に応じて最適なプランをご提案いたしますので、まずはお気軽にご相談ください。',
          },
          {
            q: '制作費用の支払いタイミングは？分割払いは可能ですか？',
            a: 'お支払いは銀行振込・現金となっており、月末締め・翌月末までにお振込みをお願いしております。基本的に分割払いには対応しておりませんが、開発期間が長くなる場合は、着手金をいただくことがございます。',
          },
          {
            q: '見積もりは無料ですか？',
            a: 'はい、無料です！ご相談からお見積りまで、費用は一切かかりません。まずはお客様のご要望を詳しくお聞かせください。',
          },
          {
            q: '契約後に追加料金が発生することはありますか？',
            a: 'ご依頼内容に大きな変更があった場合は、追加料金が発生することがあります。その際は必ず事前にご相談し、お客様のご了承をいただいた上で進めますので、ご安心ください。',
          },
          {
            q: '予算が限られているのですが、コストを抑える方法はありますか？',
            a: 'はい、可能です！ご自身で文章や写真をご用意いただくことや、ページ数を抑えることで、費用を抑えることができます。ご予算に合わせたプランのご提案もできますので、ぜひご相談ください。',
          },
        ],
      },
      {
        category: '制作内容・仕様について',
        id: 'production',
        questions: [
          {
            q: 'どのような種類のホームページを作れますか？',
            a: '企業サイトをはじめ、ECサイト・ランディングページ・CMSのカスタマイズまで幅広く対応可能です。',
          },
          {
            q: '既存サイトのリニューアルもお願いできますか？',
            a: 'もちろん可能です！特にリニューアルの場合は、事前調査が重要です。当事務所のメンバーはサイトの課題を分析し、最適な改善策を提案するのが得意ですので、安心してお任せください。',
          },
          {
            q: 'スマホ対応（レスポンシブデザイン）はできますか？',
            a: 'はい、当事務所で制作するすべてのホームページはレスポンシブ対応が基本です。スマホ・タブレット・PCどのデバイスでも見やすく快適に閲覧できるように制作いたします。',
          },
          {
            q: 'WordPress（または他のCMS）での制作は可能ですか？',
            a: 'はい、当事務所はCMSのカスタマイズを得意としています。WordPressに関しては10年以上の開発経験がありますので、あらゆるご要望に対応可能です。',
          },
          {
            q: 'デザインはオリジナルですか？テンプレートを使うこともありますか？',
            a: 'オリジナルデザインで制作いたします。お客様のブランドイメージやコンセプトに合わせ、オーダーメイドでデザインいたします。テンプレートは使用いたしません。',
          },
          {
            q: '文章や画像の用意もお願いできますか？',
            a: 'はい、可能です！プロのライターがヒアリングを行い、お客様の想いを伝える文章を作成いたします。また、プロのカメラマンが撮影を行い、高品質な写真をご提供することも可能です。',
          },
          {
            q: 'アニメーションや動きのあるサイトを作れますか？',
            a: 'はい、対応可能です！スクロールアニメーション・インタラクティブな動きなど、洗練されたデザインと技術で表現いたします。',
          },
          {
            q: 'SEO対策もしてもらえますか？',
            a: '基本的な内部のSEO対策をすべてのサイトで実施しています。また、リニューアル時は検索順位の低下を防ぐ工夫もご提案できますので、安心してご相談ください。',
          },
          {
            q: 'Webサイト公開後の修正や更新もお願いできますか？',
            a: 'はい、対応可能です！更新の頻度が高い方には、お客様のご指定のメッセージアプリでのやり取りも可能 です。',
          },
          {
            q: 'SNSとの連携も可能ですか？',
            a: 'Instagram・Twitter・LINEなどのSNS連携も可能です。LINEからの問い合わせ導線の設計など、便利な機能をご提案いたします。',
          },
        ],
      },
      {
        category: '制作期間・スケジュール',
        id: 'schedule',
        questions: [
          {
            q: 'ホームページ制作にはどれくらいの期間がかかりますか？',
            a: '約1ヶ月～3ヶ月程度での納品が一般的です。内容や規模によって異なりますので、まずはご相談ください。',
          },
          {
            q: '急ぎの対応（短納期）は可能ですか？',
            a: 'スケジュールによりますが、可能な限り対応いたします。まずはお早めにご相談ください。',
          },
          {
            q: '制作の進行状況はどのように確認できますか？',
            a: '進捗があるたびに、すぐに共有いたします。必要に応じて、お打ち合わせも可能です。クライアント側チームとの連携が必要な場合は、SlackやBacklogなど参加すべき場所があればそちらにも参加できます。',
          },
          {
            q: '途中でデザインや仕様の変更はできますか？',
            a: '状況によりますが、可能です。大幅な変更が発生する場合は、別途お見積りとなることがあります。',
          },
          {
            q: 'サイト公開後の修正対応はどれくらいの期間可能ですか？',
            a: '公開後1ヶ月程度は、細かい修正（画像入れ替えや文字の修正など）を無料で対応しております。大幅なデザイン変更については、別途ご相談ください。',
          },
        ],
      },
      {
        category: 'サポート・運用について',
        id: 'support',
        questions: [
          {
            q: 'Webサイト公開後の運用や保守もお願いできますか？',
            a: 'はい、可能です！現在も多くのお客様のサイトを運用・保守しております。',
          },
          {
            q: 'サーバーやドメインの手配もお願いできますか？',
            a: '可能です！サーバーやドメインの取得代行・管理も対応しております。',
          },
          {
            q: 'WordPressの管理方法を教えてもらえますか？',
            a: 'アップデートやセキュリティ対策を含めたサポートをご提供できます。',
          },
          {
            q: '運用サポートの費用はどのくらいですか？',
            a: 'サーバー保守費用は月額5,000円、更新サポート費用は月額5,000円。',
          },
          {
            q: 'Web広告の運用代行は可能ですか？',
            a: '可能です！広告費の20%を運用手数料としていただいております。',
          },
          {
            q: 'Webサイトのアクセス解析や改善提案もお願いできますか？',
            a: '可能です！元上級ウェブ解析士の資格を取得しておりました担当者が、サイトの改善提案を行います。',
          },
        ],
      },
      {
        category: 'トラブル',
        id: 'trouble',
        questions: [
          {
            q: '別の会社で作ったサイトでも対応できますか？',
            a: 'はい、対応可能です！ただし、必要な情報が不足している場合は、調査が難しいこともございます。まずは現在の状況をお聞かせください。',
          },
          {
            q: 'ホームページ業者と連絡がつかない場合どうすればいいですか？',
            a: 'その場合、どこまでの情報を把握しているか、どのような情報を見ればサイトが無事かなどを確認する必要があります。そういったご相談も多数お寄せいただいており、経験も豊富です。まずはお気軽にご相談ください。',
          },
          {
            q: '現在進行中の制作がうまく進まないのですがどうすればいいですか？',
            a: '現在進行中の制作がうまく進まないというご相談も多くいただいております。どのような状況になっているのかを詳しくお聞かせいただければ、何かしらのアドバイスができるかもしれません。まずはご相談ください。',
          },
        ],
      },
    ],
    []
  );

  // 各セクションの参照
  const sectionRefs = useRef({});

  // スクロール移動（目次クリック時）
  const scrollToSection = (id) => {
    const ref = sectionRefs.current[id];

    if (ref) {
      ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      console.warn('⚠️ scrollToSection: refが見つかりません', id);
    }
  };

  // FAQスキーマを生成（HTMLタグ除去の処理を削除）
  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs.flatMap((section) =>
      section.questions.map((faq) => ({
        '@type': 'Question',
        name: faq.q,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.a, // ✅ そのまま使用
        },
      }))
    ),
  };

  // 開いている質問のIDを管理する
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (id) => {
    setOpenQuestion((prev) => (prev === id ? null : id));
  };

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/faq/" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/faq" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* FAQ スキーマを追加 */}
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>

      <div className="max-w-screen-lg w-full px-8 sm:px-12 md:px-16 mx-auto py-16 sm:py-20 md:py-32 bg-white bg-opacity-90 rounded-lg">
        <h1 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12">
          FAQ
        </h1>
        <h2 className="text-3xl font-bold mb-6 text-center">よくある質問</h2>
        {/* 目次（デザイン統一 & スクロール機能） */}
        <nav className="mb-12 p-6 bg-white border border-gray-300 rounded-lg shadow-sm">
          <h2 className="text-2xl font-bold mb-4">目次</h2>
          <ul className="space-y-3">
            {faqs.map((section) => (
              <li key={section.id}>
                <button
                  className="w-full text-xl font-medium text-black hover:text-gray-700 transition-all text-left px-4 py-3 border-b border-gray-200"
                  onClick={() => scrollToSection(section.id)}
                >
                  {section.category}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        {/* FAQ一覧 */}
        {faqs.map((section) => (
          <div
            key={section.id}
            ref={(el) => {
              sectionRefs.current[section.id] = el;
            }}
            className="mb-12"
          >
            <h2 className="text-4xl font-semibold border-b pb-3 mb-8">
              {section.category}
            </h2>

            {section.questions.map((faq, index) => (
              <div key={index} className="mb-8 pb-6 border-b border-gray-300">
                <button
                  onClick={() => toggleQuestion(`${section.id}-${index}`)}
                  className="w-full flex justify-between items-center text-left text-2xl font-medium py-6 px-6 bg-white rounded-lg focus:outline-none hover:bg-gray-100 transition-all"
                >
                  <span className="flex items-center gap-3 flex-1 min-w-0">
                    <span className="text-3xl font-bold text-black flex-shrink-0">
                      Q
                    </span>
                    <span className="break-words">{faq.q}</span>
                  </span>
                  <span className="w-10 h-10 flex items-center justify-center bg-black text-white text-3xl rounded-full">
                    {openQuestion === `${section.id}-${index}` ? (
                      <AiOutlineMinus />
                    ) : (
                      <AiOutlinePlus />
                    )}
                  </span>
                </button>

                <div
                  className={`transition-all duration-300 overflow-hidden ${
                    openQuestion === `${section.id}-${index}`
                      ? 'max-h-60 opacity-100 p-6'
                      : 'max-h-0 opacity-0'
                  }`}
                >
                  <p className="text-gray-700 text-2xl leading-relaxed">
                    {faq.a}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default FAQPage;
